* {
  -webkit-print-color-adjust: exact !important;
  // color-adjust: exact !important;
}

.header .navigation,
.footer,
.subnav,
.Campaign-management-header-icon,
.header .sites-select .chosen-single span + div {
  display: none !important;
}

.header .sites-select {
  margin: 0;
}

.header,
.Campaign-management-header {
  margin-bottom: 10px;
}

.Campaign-management-list-wrapper {
  padding-top: 0;
  padding-bottom: 0;
}

#tabbed_navigation {
  display: none;
}

.shown-print {
  display: block;
}

table {
  width: 100%;
}

.pagebreak {
  page-break-before: always;
}

.weekly_stats {
  .two {
    div {
      background: #d9ead2;
      -webkit-print-color-adjust: exact;
      margin: 0 40px 20px;
      font-size: 24px;
      line-height: 24px;
      padding-bottom: 6px;
      width: 400px;
    }

    td {
      text-align: center;

      span {
        display: block;
        font-size: 40px;
        line-height: 46px;
      }
    }
  }

  .wide {
    background: #d9ead2;
    -webkit-print-color-adjust: exact;
    font-size: 24px;
    line-height: 24px;
    margin: 0 260px;
    padding-bottom: 6px;
    text-align: center;
    width: 450px;

    &.is-last {
      margin-top: 20px;
    }

    span {
      display: block;
      font-size: 40px;
      line-height: 46px;
    }
  }
}

#other_weekly_stats {
  border: 1px solid silver;

  tr th {
    background: #e6e6e6;
    -webkit-print-color-adjust: exact;
    border: 0;
    font-size: 14px;
    line-height: 30px;
    margin: 0;
    padding: 0;
  }

  tr:nth-child(odd) td {
    background: whitesmoke;
    -webkit-print-color-adjust: exact;
  }

  tr td:first-child {
    font-size: 20px;
  }

  tr td {
    font-size: 22px;
  }
}
